import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Row, Col, Container, Image, Form, Button } from "react-bootstrap";
import Layout from "../components/layout";
import Seo from "../components/seo";
import indexImage from "../images/monitoring-aerobarrier.jpg";
import serviceArea from "../images/service-area.jpg";

function encode(data) {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

const ContactUs = (props) => {
  const recaptchaRef = React.useRef();

  const [formData, setFormData] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    help: '',
  })

  const onSubmitWithReCAPTCHA = async (e) => {
    e.preventDefault();

    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      alert("Please verify you're not a robot before submitting.");
      return;
    }
    
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact v1", ...formData })
    })
      .then(() => {
         window.location.href = "/contact-thank-you/";
      })
      .catch(error => alert(error));
  };

  return (
    <Layout>
      <section className="page-section">
        <div className="masthead fixed" style={{ backgroundImage: `url(${indexImage})` }}>
          <Container className="container h-100">
            <Row className="row h-100 align-items-center">
              <Col md="12" className="text-center">
                <h1>Contact Us</h1>
              </Col>
            </Row>
          </Container>
        </div>

        <Container className="mt-5">
          <Row>
            <Col lg={8}>
              <p>Western AeroBarrier proudly serves California from San Diego to the Bay Area, Nevada including Las Vegas, and Arizona including Yuma.</p>
              <Image src={serviceArea} alt="Western Aero Barrier Service Area" fluid />
            </Col>
            <Col lg={4}>
              <p>Phone: <a href="tel:855-855-AERO" className="tel">855-855-AERO</a></p>
              <p>Email: <a href="mailto:info@WesternAerobarrier.com">info@WesternAerobarrier.com</a></p>
              <hr />
              <Form 
                data-netlify="true"
                name="contact v1"
                method="post"
                onSubmit={onSubmitWithReCAPTCHA}
              >
                <input type="hidden" name="form-name" value="contact v1" />
                <p hidden>
                  <label>
                    Don’t fill this out: <input name="bot-field" />
                  </label>
                </p>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control value={formData.firstName} onChange={(e) => setFormData({...formData, firstName: e.target.value})} type="text" name="firstName" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control value={formData.lastName} onChange={(e) => setFormData({...formData, lastName: e.target.value})} type="text" name="lastName" />
                    </Form.Group>
                  </Col>
                </Row>                            
                <Form.Group>
                  <Form.Label>Email address</Form.Label>
                  <Form.Control value={formData.email} onChange={(e) => setFormData({...formData, email: e.target.value})} required type="email" name="email" />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Phone</Form.Label>
                  <Form.Control value={formData.phone} onChange={(e) => setFormData({...formData, phone: e.target.value})} type="text" name="phone" />
                </Form.Group>
                <Form.Group>
                  <Form.Label>How can we help you?</Form.Label>
                  <Form.Control value={formData.help} onChange={(e) => setFormData({...formData, help: e.target.value})} as="textarea" name="help" rows="4" />
                </Form.Group>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="normal"
                  sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
                />
                <Button className="mt-2" type="submit">Contact Us</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default ContactUs;

export const Head = () => <Seo title="Contact Us" />
